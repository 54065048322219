.container {
    display: grid;
    width: 1366px;
    height: 768px;
    grid-template-areas:
    "photoSlider headerMenu headerMenu"
    "photoSlider groupTitle itemsMenu"
    "mediaPlayer groupTitle itemsMenu"
    "newsSlider  newsSlider newsSlider"
    ;
}

.photoSlider {
    grid-area: photoSlider;
    background-color: green;
    width: 366px;
    height: 517px;
}

.mediaPlayer {
    grid-area: mediaPlayer;
    background-color: blue;
    height: 206px;
    width: 366px;
}

.newsSlider {
    grid-area: newsSlider;
    background-color: red;
    width: 1366px;
    height: 45px;
}

.groupTitle {
    grid-area: groupTitle;
    background-color: darkgoldenrod;
    width: 40px;
}

.headerMenu {
    grid-area: headerMenu;
    display: grid;
    background-color: white;
    height: 100px;
    width: 1000px;
    grid-template-areas:
    "banner logo prevtempo"
    ;
}

.headerMenu.banner {
    grid-area: banner;
    width: 500px;
    height: 100px;
    background-color: brown;
}

.headerMenu.logo {
    grid-area: logo;
    width: 200px;
    height: 100px;
    background-color: darkgreen;
}

.headerMenu.prevtempo {
    grid-area: prevtempo;
    width: 300px;
    height: 100px;
    background-color: white;
    position: relative;
}

.headerMenu.imgcontainer {
    width: 272px;
    height: 86px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -45px 0 0 -138px;
    overflow: hidden;
}

.headerMenu.img {
    width: 276px;
    height: 90px;
    margin: -2px 0 0 -2px;
}

.itemsMenu {
    grid-area: itemsMenu;
    background-color: purple;
    width: 960px;
}